import React from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider, View, Image, useTheme, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomePage from './pages/Homepage';
import ChatInterface from './pages/ChatBot';
import Sidebar from './components/SideBar';
import Modelos from './pages/Modelos';
import './App.css';

import { I18n } from 'aws-amplify/utils';
import { translations } from '@aws-amplify/ui-react';

import customLogo from './img/logo_global.png';

Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabularies({
  es: {
    'Sign In': 'Iniciar Sesión',
    'Sign in': 'Iniciar Sesión',
    'Sign Up': 'Registrarse',
    'Create Account': 'Crear Cuenta',
    'Username': 'Usuario',
    'Enter your username': 'Introduce tu usuario',
    'Password': 'Contraseña',
    'Enter your password': 'Introduce tu contraseña',
    'Email': 'Correo Electrónico',
    'Confirm Password': 'Confirmar Contraseña',
    'Forgot your password?': '¿Olvidaste tu contraseña?',
    'Reset password': 'Restablecer contraseña',
    'No account?': '¿No tienes cuenta?',
    'Create account': 'Crear cuenta',
    'Have an account?': '¿Tienes una cuenta?',
    'Sign in to your account': 'Ingrese a su cuenta',
  }
});

const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
      <Image
        alt="Gestor logo"
        src={customLogo}
        style={{ width: '150px', height: 'auto' }} // Ajusta el tamaño aquí
      />
     <Heading level={3} padding={tokens.space.medium} style={{ color: 'white' }}>
          Bienvenido a Gestor Global
        </Heading>
    </View>
    );
  }
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Introduce tu email',
    },
    password: {
      placeholder: 'Introduce tu contraseña',
    },
  },
  signUp: {
    username: {
      placeholder: 'Introduce tu email',
    },
    password: {
      placeholder: 'Introduce tu contraseña',
    },
    confirm_password: {
      placeholder: 'Confirma tu contraseña',
    },
    email: {
      placeholder: 'Introduce tu correo electrónico',
    },
  },
};

const labels = {
  es: {
    'Sign In': 'Iniciar Sesión',
    'Sign Up': 'Registrarse',
    'Username': 'Usuario',
    'Password': 'Contraseña',
    'Email': 'Correo Electrónico',
    'Confirm Password': 'Confirmar Contraseña',
    'Forgot your password?': '¿Olvidaste tu contraseña?',
    'Reset password': 'Restablecer contraseña',
    'No account?': '¿No tienes cuenta?',
    'Create account': 'Crear cuenta',
    'Have an account?': '¿Tienes una cuenta?',
    'Sign in': 'Iniciar Sesión',
  }
};

function App({ signOut, user }) {
  return (
    <Router>
      <div style={{ display: 'flex', height: '100vh' }}>
        <Sidebar signOut={signOut} user={user} />
        <div style={{ flexGrow: 1, padding: '20px' }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/dashboard" element={<ChatInterface />} />
            <Route path="/modelos" element={<Modelos />} />
            <Route path="/chatbot" element={<ChatInterface />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

const theme = {
  name: 'custom-theme',
  tokens: {
    colors: {
      background: {
        primary: { value: '#ffffff' },
        secondary: { value: '#f0f0f0' },
      },
      font: {
        primary: { value: '#000000' },
      },
    },
  },
};

export default function AppWithAuth() {
  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        components={components}
        formFields={formFields}
        labels={labels['es']}
      >
        {({ signOut, user }) => <App signOut={signOut} user={user} />}
      </Authenticator>
    </ThemeProvider>
  );
}
