import React from 'react';
import {  Typography, Box, Container, Paper, Grid } from '@mui/material';
import logo from '../img/logo_global.png'; // Ajusta la ruta si es necesario

function HomePage() {
    return (
        <Box sx={{ display: 'flex' }}>
            <Container maxWidth="lg" sx={{ marginLeft: '' }}>
                <Box my={4} display="flex" flexDirection="column" alignItems="center">
                    <img src={logo} alt="Asesoría Contable Logo" style={{ width: 200, margin: '20px' }} />
                    <Typography variant="h3" component="h1" gutterBottom>
                        Asesoría Contable con IA
                    </Typography>
                    <Typography variant="h6" align="center" color="textSecondary" paragraph>
                        Bienvenido a nuestra plataforma de asesoría contable basada en Inteligencia Artificial.
                        Ofrecemos soluciones innovadoras y personalizadas para gestionar tus finanzas con
                        precisión y eficiencia.
                    </Typography>
                    <Paper elevation={3} style={{ padding: '20px', marginTop: '30px', width: '100%' }}>
                        <Typography variant="h5" component="h3">
                            Nuestros Servicios:
                        </Typography>
                        <ul>
                            <li>Optimización de procesos contables mediante IA.</li>
                            <li>Asesoría personalizada adaptada a tus necesidades.</li>
                            <li>Generación de reportes financieros detallados y precisos.</li>
                            <li>Soporte continuo y actualizaciones según la normativa española.</li>
                        </ul>
                    </Paper>
                  
                </Box>
            </Container>
        </Box>
    );
}

export default HomePage;
