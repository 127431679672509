import React, { useState } from 'react';
import { Drawer, List, Divider, ListItemButton, ListItemIcon, ListItemText, Box, IconButton } from '@mui/material';
import Dataset from '@mui/icons-material/Dataset';
import HomeIcon from '@mui/icons-material/Home';
import ChatBubble from '@mui/icons-material/Chat';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';
import headerImage from '../img/logo_global.png'; // Asegúrate de que la ruta es correcta

const drawerWidth = 240;
const collapsedWidth = 60;

function Sidebar({ signOut }) {
    const [collapsed, setCollapsed] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const toggleDrawer = () => {
        setCollapsed(!collapsed);
    };

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { md: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                variant="permanent"
                sx={{
                    width: collapsed ? collapsedWidth : drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: collapsed ? collapsedWidth : drawerWidth,
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#000000',
                        color: 'white',
                        transition: 'width 0.3s',
                    },
                }}
                open
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0 8px',
                        backgroundColor: '#130a19',
                    }}
                >
                    <IconButton
                        onClick={toggleDrawer}
                        sx={{
                            color: 'white',
                            marginRight: 'auto', // Asegura que el icono esté alineado a la izquierda
                        }}
                    >
                        {collapsed ? <MenuIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </Box>
                <Divider />
                <Box
                    sx={{
                        height: '120px',
                        backgroundColor: '#130a19',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        flexDirection: collapsed ? 'column' : 'row',
                        padding: collapsed ? '10px 0' : '0',
                    }}
                    component={Link}
                    to="/"
                >
                    <img src={headerImage} alt="Cabecera" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </Box>
                <List>
                    <ListItemButton component={Link} to="/">
                        <ListItemIcon><HomeIcon style={{ color: 'white' }} /></ListItemIcon>
                        {!collapsed && <ListItemText primary="Home" />}
                    </ListItemButton>
                    <ListItemButton component={Link} to="/modelos">
                        <ListItemIcon><Dataset style={{ color: 'white' }} /></ListItemIcon>
                        {!collapsed && <ListItemText primary="Modelos" />}
                    </ListItemButton>
                    <ListItemButton component={Link} to="/chatbot">
                        <ListItemIcon><ChatBubble style={{ color: 'white' }} /></ListItemIcon>
                        {!collapsed && <ListItemText primary="ChatBot" />}
                    </ListItemButton>
                    <Divider />
                </List>
                <Box sx={{ flexGrow: 1 }} />
                <List>
                    <ListItemButton component={Link} to="/profile">
                        <ListItemIcon><AccountCircleIcon style={{ color: 'white' }} /></ListItemIcon>
                        {!collapsed && <ListItemText primary="Profile" />}
                    </ListItemButton>
                    <ListItemButton onClick={signOut}>
                        <ListItemIcon><ExitToAppIcon style={{ color: 'white' }} /></ListItemIcon>
                        {!collapsed && <ListItemText primary="Sign Out" />}
                    </ListItemButton>
                </List>
            </Drawer>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                <List>
                    <ListItemButton component={Link} to="/">
                        <ListItemIcon><HomeIcon /></ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/chatbot">
                        <ListItemIcon><Dataset /></ListItemIcon>
                        <ListItemText primary="Modelos" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/chatbot">
                        <ListItemIcon><ChatBubble /></ListItemIcon>
                        <ListItemText primary="ChatBot" />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton component={Link} to="/profile">
                        <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                        <ListItemText primary="Profile" />
                    </ListItemButton>
                    <ListItemButton onClick={signOut}>
                        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                        <ListItemText primary="Sign Out" />
                    </ListItemButton>
                </List>
            </Drawer>
        </>
    );
}

export default Sidebar;
