import OpenAI from "openai";

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true // Recuerda los riesgos de seguridad asociados
});

export const createThread = async () => {
  try {
    const thread = await openai.beta.threads.create();
    return thread;
  } catch (error) {
    console.error("Error creating thread:", error);
    throw error;
  }
};

export const retrieveThread = async (threadId) => {
  try {
    const thread = await openai.beta.threads.retrieve(threadId);
    return thread;
  } catch (error) {
    console.error("Error retrieving thread:", error);
    throw error;
  }
};

export const updateThread = async (threadId, metadata) => {
  try {
    const updatedThread = await openai.beta.threads.update(threadId, { metadata });
    return updatedThread;
  } catch (error) {
    console.error("Error updating thread:", error);
    throw error;
  }
};

export const deleteThread = async (threadId) => {
  try {
    const response = await openai.beta.threads.del(threadId);
    return response;
  } catch (error) {
    console.error("Error deleting thread:", error);
    throw error;
  }
};

// export const createMessage = async (threadId, content) => {
//   try {
//     const message = await openai.beta.threads.messages.create(threadId, {
//       role: "user",
//       content
//     });
//     return message;
//   } catch (error) {
//     console.error("Error creating message:", error);
//     throw error;
//   }
// };
export const createMessage = async (threadId, content) => {
  try {
    const message = await openai.beta.threads.messages.create(threadId, { 
      role: "user", 
      content 
    });
    console.log("Message created:", message);
    return message;
  } catch (error) {
    console.error("Error creating message:", error);
    throw error;
  }
};


// export const createRun = async (threadId, assistantId) => {
//   try {
//     const run = await openai.beta.threads.runs.create(threadId, {
//       assistant_id: assistantId,
//       // Puedes añadir más parámetros aquí según necesites, como model, instructions, etc.
//     });
//     return run;
//   } catch (error) {
//     console.error("Error creating run:", error);
//     throw error;
//   }
// };
export const createRun = async (threadId, assistantId) => {
  try {
    const stream = await openai.beta.threads.runs.create(threadId, {
      assistant_id: assistantId,
      stream: true
    });

    const events = [];
    for await (const event of stream) {
      console.log(event);
      events.push(event);
    }
    return events; // Returning all events for further processing
  } catch (error) {
    console.error("Error creating run with streaming:", error);
    throw error;
  }
};



export const listMessages = async (threadId) => {
  try {
    // Suponiendo que la API de OpenAI ofrece un endpoint para listar mensajes en un hilo
    const messages = await openai.beta.threads.messages.list(threadId);
    return messages;
  } catch (error) {
    console.error("Error listing messages:", error);
    throw error;
  }
};


export const retrieveMessage = async (threadId, messageId) => {
  try {
    const message = await openai.beta.threads.messages.retrieve(threadId, messageId);
    return message;
  } catch (error) {
    console.error("Error retrieving message:", error);
    throw error;
  }
};

export const createThreadAndRun = async (assistantId, initialMessage) => {
  try {
    const stream = await openai.beta.threads.createAndRun({
      assistant_id: assistantId,
      thread: {
        messages: [
          { role: "user", content: initialMessage },
        ],
      },
      stream: true
    });

    for await (const event of stream) {
      console.log(event);
      // Here you can handle the events as they come in.
    }
  } catch (error) {
    console.error("Error creating thread and run with streaming:", error);
    throw error;
  }
};
