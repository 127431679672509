import React, { useState, useEffect} from 'react';
import { Box, Button, TextField, Typography, Paper, Grid, Card, CardContent, Avatar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import gestoraLogo from '../img/logo_global.png';
import UserAvatar from '../img/profile_user.jpeg'; // Asume que tienes una imagen para el avatar del usuario
import { createThread, createMessage, createRun, listMessages } from '../services/threadService';



const ChatBot = () => {
  const [input, setInput] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [conversation, setConversation] = useState([]);
  const assistantId = "asst_XaRv95WKUldJG5LFJl1BbnfQ"; // Asegúrate de reemplazar esto con el ID de tu asistente

  useEffect(() => {
    const init = async () => {
      try {
        const thread = await createThread();
        setThreadId(thread.id);
        console.log("Thread ID:", thread.id); // Esto debería mostrarte el ID del hilo creado
      } catch (error) {
        console.error("Error al crear el hilo:", error);
      }
    };

    init();
  }, []);
  const [tempAssistantMessage, setTempAssistantMessage] = useState('');


  const handleSend = async () => {
    if (!input.trim() || !threadId) return;

    try {
      // Envía el mensaje del usuario
      await createMessage(threadId, input);
      console.log("Mensaje creado en el hilo:", input);
      // Agrega el mensaje del usuario a la conversación
      setConversation(prev => [...prev, { role: "user", content: input }]);
      // Limpia el input
      setInput('');

      // Inicia el run y maneja el stream de eventos
      const events = await createRun(threadId, assistantId);
      let tempAssistantMessage = ''; // Inicializa un mensaje temporal para el asistente
      for await (const event of events) {
        console.log(event);
        // Acumula los fragmentos de texto en tempAssistantMessage
        if (event.event === 'thread.message.delta' && event.data.delta.content) {
          const messageFragment = event.data.delta.content.map(c => c.text.value).join('');
          tempAssistantMessage += messageFragment;
        }
        // Cuando el mensaje esté completo, agrega a la conversación y limpia tempAssistantMessage
        if (event.event === 'thread.message.completed') {
          setConversation(prev => [...prev, { role: "assistant", content: tempAssistantMessage }]);
          tempAssistantMessage = ''; // Limpia el mensaje temporal
        }
      }
    } catch (error) {
      console.error("Error durante el envío del mensaje o la ejecución:", error);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>

    <Paper elevation={3} sx={{ maxWidth: 700, margin: 'auto', padding: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
        <img src={gestoraLogo} alt="Acuamattic Logo" style={{ width: 200, margin: '20px' }} />
      </Box>
      <Typography variant="h5" textAlign="center" gutterBottom>
        Gestor IA v1.0
      </Typography>
      <Box sx={{
        maxHeight: '600px',
        overflowY: 'auto',
        marginBottom: 2,
        padding: 2,
        bgcolor: 'background.paper',
        boxShadow: 1,
        borderRadius: 1,
      }}>
        <Grid container spacing={2}>
          {conversation.map((msg, index) => (
            <Grid item xs={12} key={index} sx={{ display: 'flex', justifyContent: msg.role === "user" ? 'flex-end' : 'flex-start' }}>
              <Card sx={{
                maxWidth: 400,
                bgcolor: msg.role === "user" ? 'primary.light' : '#c3ccdb',
                order: msg.role === "user" ? 2 : 1 // Esto asegura que los mensajes del usuario se alineen a la derecha
              }}>
                <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar src={msg.role === "user" ? UserAvatar : gestoraLogo} sx={{ marginRight: 1 }} />
                  <Typography variant="body1">
                    {msg.content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          placeholder="Escribe tu pregunta..."
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        />
        <Button variant="contained" color="primary" sx={{ marginLeft: 2 }} onClick={handleSend}>
          <SendIcon />
        </Button>
      </Box>
    </Paper>
    </Box>
  );
};

export default ChatBot;