import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
// Librerias importadas

import { ThemeProvider, CssBaseline } from '@mui/material';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports'; // Asegúrate de que la ruta es correcta


// Paginas importadas
import App from './App';
import theme from './theme';



Amplify.configure(awsExports);


Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();