import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import IRPFImage from '../img/irpf.webp'; // Asegúrate de que la ruta sea correcta

const UnderConstruction = () => {
  return (
    <Container sx={{ textAlign: 'center', paddingTop: 10 }}>
      <Box display="flex" justifyContent="center" mb={4}>
        <ConstructionIcon sx={{ fontSize: 80, color: '#f57c00' }} />
      </Box>
      <Typography variant="h3" component="h1" gutterBottom>
        Funcionalidad en Construcción
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Estamos trabajando en la generación de modelos de IRPF para España
      </Typography>
      <Typography variant="body1" color="textSecondary" paragraph>
        Próximamente añadiremos esta funcionalidad para que puedas generar y gestionar tus modelos de IRPF de manera sencilla y eficiente. ¡Gracias por tu paciencia!
      </Typography>
      <Box display="flex" justifyContent="center" my={4}>
        <img src={IRPFImage} alt="IRPF Model" style={{ width: '50%', maxWidth: 400 }} />
      </Box>
      <Button variant="contained" color="primary" href="/">
        Volver a la página principal
      </Button>
    </Container>
  );
};

export default UnderConstruction;
